let storedAside : undefined | null | HTMLElement = undefined
const storedHolders: ElementMap = {}

export function removeHolder(name: string) : void {
    if (!storedAside) {
        return;
    }

    const element : HTMLElement | null = storedHolders[name];

    if (element) {
        element.remove();

        delete storedHolders[name];
    }
}

export function getHolder(name: string) : HTMLElement {
    if (!storedAside) {
        storedAside = document.getElementById('main');

        if (!storedAside) {
            throw new Error("Document is missing an element of ID 'main'.");
        }
    }

    let element : HTMLElement | null = storedHolders[name];

    if (element) {
        return element;
    }

    element = storedAside.querySelector(`[data-${hashCode(name)}]`)

    if (!element) {
        element = document.createElement('div');
        element.setAttribute(`data-${hashCode(name)}`, '')
        element.id = name;
        storedAside.appendChild(element);
    }

    storedHolders[name] = cEwO(element)

    return storedHolders[name];
}

export async function fetchModMeta(mod: GitHubRepository) : Promise<WorkerRepoMeta> {
    return await fetchPage(`https://versioning.boomy.wtf/meta?repo=${mod.full_name}`);
}

export async function fetchModsList() : Promise<Array<GitHubRepository>> {
    return await fetchPage('https://versioning.boomy.wtf/repos').then((data: Array<GitHubRepository>) => {
        data.sort((a, b) => {
            const aTime = new Date(a.pushed_at).getTime();
            const bTime = new Date(b.pushed_at).getTime();

            return aTime < bTime ? 1 : aTime > bTime ? -1 : 0;
        })

        return data;
    });
}

let urlStorageObject: RequestObject | undefined = undefined;

function getURLStorage() : RequestObject {
    if (urlStorageObject) {
        return urlStorageObject;
    }

    const newObject = localStorage.getItem('requests');

    if (!newObject) {
        urlStorageObject = {};
        localStorage.setItem('requests', '{}')
    } else {
        try {
            urlStorageObject = JSON.parse(newObject)
        } catch (e) {
            urlStorageObject = {};
            localStorage.setItem('requests', '{}')
        }
    }

    return urlStorageObject as RequestObject
}

async function fetchPage(url: string, opts: RequestInit | undefined = undefined) : Promise<any> {
    const hashed = hashCode(url).toString();
    const stored = getURLStorage()[hashed];

    if (stored) {
        const data = JSON.parse(unescape(atob(stored)));

        if (Date.now() < data.expiryTime) {
            console.log(`Using cached ${url}`)

            return data.data;
        }
    }

    console.log(`Fetching ${url}`)

    const data = await fetch(url, opts).then(async (result: Response) => { return await (result.json()) })

    const dataObject = {
        expiryTime: Date.now() + 30 * 60 * 1000,
        data: data
    }

    getURLStorage()[hashed] = btoa(escape(JSON.stringify(dataObject)));
    localStorage.setItem('requests', JSON.stringify(urlStorageObject))

    return data;
    // return await fetch(url, opts).then(async (result: Response) => { return await (result.json()) });
}

export function createSVG(text: string) : string {
    const hashed = hashCode(text);
    const color = `hsla(${~~(hashed % 360)}, 70%,  72%, 0.8)`
    const colorText = `hsla(${~~(hashed % 360)}, 70%,  22%, 0.8)`

    const svg = [];
    svg.push('<svg xmlns="http://www.w3.org/2000/svg">')
    svg.push('<g>')
    svg.push(`<rect x="0" y="0" width="100%" height="100%" fill="${color}"></rect>`)


    if (text.length <= 10) {
        svg.push(`<text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="sans-serif" font-weight="bold" font-size="14" fill="${colorText}">${text}</text>`)
    } else {
        const split: Array<string> = [];

        let tempText = text;

        while (tempText.length > 10) {
            split.push(tempText.substring(0, 11).trim());

            tempText = tempText.substring(11, tempText.length).trim();
        }

        if (tempText.length > 0 && split.indexOf(tempText) <= -1) {
            split.push(tempText)
        }

        const startingPoint = 50 - ((split.length - 1) / 2) * 15

        for (let i = 0; i < split.length; i++) {
            svg.push(`<text x="50%" y="${startingPoint + (i * 15)}%" dominant-baseline="middle" text-anchor="middle" font-family="sans-serif" font-weight="bold" font-size="14" fill="${colorText}">${split[i]}</text>`)
        }
    }

    svg.push('</g>')
    svg.push('</svg>')

    return `data:image/svg+xml;base64,${  btoa(svg.join(''))}`
}

export function dateToReadable(date: undefined | string) : string | undefined {
    if (!date) {
        return undefined;
    }

    const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) {
        return floorAndPluralize(interval, 'year');
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return floorAndPluralize(interval, 'month');
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return floorAndPluralize(interval, 'day');
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return floorAndPluralize(interval, 'hour');
    }
    interval = seconds / 60;
    if (interval > 1) {
        return floorAndPluralize(interval, 'minute');
    }
    return floorAndPluralize(interval, 'second');
}

export function holder(...nodes: Array<HTMLElement>) : HTMLElement {
    return cEwO('div', { elements: nodes });
}

export function getById(id: string) : HTMLElement {
    let obj = document.getElementById(id)

    if (!obj) {
        obj = document.createElement('div');
        obj.id = id;

        document.getElementById('side')?.appendChild(holder(obj));
    }

    return cEwO(obj);
}

function addChild(parent: HTMLElement, child: Node | string | Array<Node | string>) : void {
    if (!Array.isArray(child)) {
        child = [ child ];
    }

    for (let i = 0; i < child.length; i++) {
        const toAppend = child[i];

        if (typeof toAppend === 'string') {
            parent.appendChild(document.createTextNode(toAppend))
        } else {
            parent.appendChild(toAppend);
        }
    }
}

export function cEwO<K extends keyof HTMLElementTagNameMap>(element: K | HTMLElement, opts?: cEwOOptions | string) : HTMLElementTagNameMap[K] {
    const data = typeof element === 'string' ? document.createElement(element) : element;

    if (opts) {
        if (typeof opts === 'string') {
            data.appendChild(document.createTextNode(opts))
        } else {
            if (opts.elements) {
                addChild(data, opts.elements)
            }

            // A couple of common options.
            opts.id ? data.id = opts.id : '';
            opts.classList ? Array.isArray(opts.classList) ? data.classList.add(...opts.classList) : data.classList.add(opts.classList) : '';
        }
    }

    // a hack
    return data as unknown as HTMLElementTagNameMap[K];
}

function floorAndPluralize(input: number, word: string) : string {
    const floored = Math.floor(input);

    return `${floored} ${word}${floored === 1 || floored === -1 ? '' : 's'}`
}

function shortenNumber(n: number) : string {
    if (n < 1e3) return n.toString();
    if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}K`;
    if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}M`;
    if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}B`;
    if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}T`;
    return n.toString();
}

export function getTotalDownloads(asset: GitHubRelease) : string {
    let total = 0;

    for (let i = 0; i < asset.assets.length; i++) {
        total += asset.assets[i].download_count;
    }

    return shortenNumber(total)
}

export function hashCode(string: string) : number {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
        const code = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + code;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash < 0 ? -1 * hash : hash;
}
