
const repositoryOverrides: OverrideMap = {
    'boomboompower/twitch-experiments': {
        name: 'Twitch Experiment Tracker',
        description: 'A tool which automatically tracks active experiments on the site twitch.tv & allows you to generate a cookie to override them.',
        name_override: 'TECG'
    },
    'boomboompower/SkinChanger': {
        topics: ['Client', 'Utility', 'Forge', 'Fabric'],
        display_picture: '/images/1659831909.png'
    },
    'boomboompower/ToggleChat': {
        topics: ['Client', 'Utility', 'Forge', 'Fabric'],
        display_picture: '/images/1140511980.png',
        description: 'A Forge mod for 1.8.9 that allows you to toggle various Hypixel chats with the click of a button. ',
    },
    'boomboompower/HurtAnimationRemover': {
        name: 'HurtAnimationRemover',
        name_override: 'Hurt Animation Remover',
    },
    'boomboompower/HurtAnimationRemoverFabric': {
        name: 'HurtAnimationRemover-Fabric',
        name_override: 'Hurt Animation Remover Fabric',
        description: 'Disables the screen shake while being hurt. But for Fabric'
    },
    'Skytils/SkytilsMod': {
        topics: ['Client', 'Utility', 'Forge', 'Fabric'],
    }
}

export default function getOverride(name: string) : undefined | object {
    return repositoryOverrides[name];
}
